/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFWJ0bbck.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFUZ0bbck.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFWZ0bbck.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFVp0bbck.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFWp0bbck.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(./../../../assets/fonts/opensans/mem8YaGs126MiZpBA-UFW50bbck.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url(https://fonts.gstatic.com/s/opensans/v16/mem8YaGs126MiZpBA-UFVZ0b.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOX-hpOqc.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOVuhpOqc.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOXuhpOqc.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}

/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOUehpOqc.woff2) format('woff2');
  unicode-range: U+0370-03FF;
}

/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOXehpOqc.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}

/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(./../../../assets/fonts/opensans/mem5YaGs126MiZpBA-UNirkOXOhpOqc.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(https://fonts.gstatic.com/s/opensans/v16/mem5YaGs126MiZpBA-UNirkOUuhp.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* SFUIText */
@font-face {
  font-family: 'SFUIText-Regular';
  font-style: normal;
  font-weight: normal;
  src: url(./../../../assets/fonts/SFUIText/SFUIText-Regular.ttf) format('ttf');
}

@font-face {
  font-family: 'SFUIText-Semibold';
  font-style: normal;
  font-weight: normal;
  src: url(./../../../assets/fonts/SFUIText/SFUIText-Semibold.ttf) format('ttf');
}

@font-face {
  font-family: 'SFUIText-Bold';
  font-style: normal;
  font-weight: normal;
  src: url(./../../../assets/fonts/SFUIText/SFUIText-Bold.ttf) format('ttf');
}

@font-face {
  font-family: "CenturyGothic";
  src: local("CenturyGothic"), url("./../../../assets/fonts/centurygothic.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"), url("./../../../assets/fonts/rubik.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Rubiklight";
  src: local("Rubiklight"), url("./../../../assets/fonts/rubiklight.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "LucidaSans";
  src: local("LucidaSans"), url("./../../../assets/fonts/lucidasans.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "ArialNarrow";
  src: local("ArialNarrow"), url("./../../../assets/fonts/arialnarrow.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url("./../../../assets/fonts/verdana.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "DMSans";
  src: local("DMSans"), url("./../../../assets/fonts/DMSans-Regular.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Trirong";
  src: local("Trirong"), url("./../../../assets/fonts/Trirong-Regular.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./../../../assets/fonts/Montserrat-Regular.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Montserratlight";
  src: local("Montserratlight"), url("./../../../assets/fonts/Montserrat-Light.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "ErasMedium";
  src: local("ErasMedium"), url("./../../../assets/fonts/erasmedium.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "ErasMediumITC";
  src: local("ErasMediumITC"), url("./../../../assets/fonts/erasmediumitc.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "MuseoSans100";
  src: local("MuseoSans100"), url("./../../../assets/fonts/MuseoSans_100.otf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "MuseoSans500";
  src: local("MuseoSans500"), url("./../../../assets/fonts/MuseoSans_500.otf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "MuseoSans700";
  src: local("MuseoSans700"), url("./../../../assets/fonts/MuseoSans_700.otf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "MuseoSans900";
  src: local("MuseoSans900"), url("./../../../assets/fonts/MuseoSans_900.otf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Caslon";
  src: local("Caslon"), url("./../../../assets/fonts/caslon.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "CaslonMedium";
  src: local("CaslonMedium"), url("./../../../assets/fonts/caslonmedium.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Mont";
  src: local("Mont"), url("./../../../assets/fonts/mont.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "GillsansLight";
  src: local("GillsansLight"), url("./../../../assets/fonts/GillSans-Light.ttf") format("truetype");
  /*non-IE*/
}

@font-face {
  font-family: "Avalon";
  src: local("Avalon"), url("./../../../assets/fonts/Avalon-Regular.ttf") format("truetype");
  /*non-IE*/
}
