@import "../settings/theme-variables";

/**  =====================
     Dark css start
==========================  **/
body.able-pro-dark {
  .a-link {
    color: #adb7be !important;
  }

  .input-group {
    background-color: #0f192f;
  }

  .card {
    .card-header {
      border-bottom: 1px solid #0a1120;
    }
  }

  .table thead th.caption {
    color: #fff;
    border: 1px solid #0a1120;
    background-color: #0a1120;
  }

  .form-table {
    .table-label {
      background-color: #0f192f;
      color: #adb7be;
      width: 25%;
      text-align: right;
    }
  }

  .ng-select {
    .ng-select-container {
      background-color: #0a1120;
      border: 1px solid #0a1120;

      .ng-value-container {
        .ng-value {
          color: #adb7be;
        }
      }
    }
  }

  .form-group input:not([type='checkbox']),
  .form-group select:not([type='checkbox']) {
    border: 1px solid #030408;
  }

  .ngx-datatable-caption {
    color: #fff;
    background-color: #0a1120;
    border: 1px solid #0a1120;
  }

  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
  .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group {
    background: #0a1120;
  }


  .ngx-datatable.material {
    background: transparent;

    .datatable-body {
      .datatable-row-detail {
        background: #0a1120;
      }
    }

    &.striped {
      .datatable-row-odd {
        background: #0a1120;
      }
    }

    &.bordered {
      border: 1px solid #0a1120;
    }

    .datatable-header {
      .datatable-header-cell {
        color: #adb7be;
        background-color: #0f192f;
      }
    }

    .datatable-body-row,
    .datatable-row-odd {
      background-color: #0f192f;

      .datatable-body-cell {
        color: #adb7be;
      }
    }
  }

  :host ::ng-deep .uw-accordion {
    .card {
      .card-header {
        background-color: #0c1527;

        .btn-link {
          a {
            color: #fff;
          }
        }

      }

      .card-body {
        .table thead th {
          background: #0f192f;
          color: #fff;
        }
      }
    }
  }

  .custom-mini-table {

    td {
      background: #263238;
    }

    th {
      background: #11171a;
    }
  }

  .modal-header,
  .modal-content {
    background: #222a38;
  }

  .modal-footer {
    border-top: none !important;
  }
}
