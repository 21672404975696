/*
global
*/
@import "../settings/theme-variables";

.bg-lightblue, .badge-lightblue {
  background-color: lightblue;
}

.bg-lightgreen, .badge-lightgreen {
  background-color: #b4f7b4;
}

.bg-green, .badge-green {
  background-color: #2ac92a;
}

.bg-blue, .badge-blue {
  background-color: #4680ff;
}

.text-gray {
  color: #727e8c;
}

.text-lightgray {
  color: #c5c5c5;
}

.text-green {
  color: #2ac92a;
}

.text-orange {
  color: #ff8000;
}

.bg-light-purple {
  background-color: #EAE6F2;
}

.border-radius-0 {
  border-radius: 0;
}

.a-link,
.text-purple {
  color: #542f9e;
}

.auto-width {
  min-width: auto !important;
  width: auto;
}

.auto-width-select {
  width: auto !important;

  .ng-dropdown-panel {
    width: auto !important;
    min-width: 100%;
  }
}

.btn {
  height: 36px;
  padding: 0 1rem;
  line-height: 1;
  border-radius: 0;
  min-width: 100px;
}

.btn-purple {
  background: $purple;
  border-color: $purple;
  color: #fff;

  &:hover {
    color: #fff;
    background: $indigo;
    border-color: $indigo;
  }
}

.btn-outline-purple {
  border-color: #e7e0f5;
  color: $purple;
  background-color: #e7e0f5;

  &:hover {
    color: #fff;
    background: $indigo;
    border-color: $indigo;
  }
}

/* ngx datatable */
.ngx-datatable.material {
  box-shadow: none;

  .datatable-header {
    min-width: 100%;
  }

  .datatable-body {
    min-width: 100%;

    .datatable-scroll {
      width: 100% !important;
    }
  }

  .datatable-header {
    .datatable-header-cell {
      font-size: 14px;
      color: #333;
      font-weight: bold;

      &:hover {
        .resize-handle {
          border-right: none;
        }
      }
    }
  }

  .datatable-row-left {
    background-image: none !important;
  }

  &.striped .datatable-row-odd {
    .datatable-row-left {
      background-color: #eee !important;
    }
  }

  &.bordered {
    border: 1px solid #e2e5e8;
  }

  &.mini {
    .datatable-header {
      .datatable-header-cell {
        padding: 10px 1.2rem;
      }
    }

    .datatable-body {
      .datatable-body-row .datatable-body-cell {
        padding: 10px 1.2rem;
      }
    }
  }

  &.highlighter-header {
    .datatable-header {
      .datatable-header-cell {
        background-color: #542f9e;
        color: #fff;
      }
    }
  }
}

.table thead th.caption {
  color: #fff;
  border: 1px solid #542f9e;
  background-color: #542f9e;
}

.ngx-datatable-caption {
  color: #fff;
  background-color: #542f9e;
  border: 1px solid #542f9e;
  padding: 10px 1.2rem;
  font-weight: 600;
}

// ngx-bootstrap-datepicker
.theme-purple .bs-datepicker-body table td.week span {
  color: #542f9e;
}

.theme-purple .bs-datepicker-head,
.theme-purple .bs-datepicker-body table td span.selected,
.theme-purple .bs-datepicker-body table td.selected span,
.theme-purple .bs-datepicker-body table td span[class*="select-"]:after,
.theme-purple .bs-datepicker-body table td[class*="select-"] span:after {
  background-color: #542f9e;
}

.theme-purple .bs-datepicker-buttons .btn {
  background-color: #542f9e;
  border-color: #542f9e;
}

.theme-purple {
  .btn-clear-wrapper,
  .btn-today-wrapper {
    .btn-success:not(:disabled):not(.disabled):active {
      background-color: #542f9e;
      border-color: #542f9e;
    }
  }
}

// card
.card-panel {
  border-radius: 0;
}

input.form-control,
select.form-control {
  height: 36px;
  // border-radius: 2px;
  font-size: 14px;

  &:not(.is-invalid) {
    border: 1px solid #ccc;
  }

  &:focus {
    border: 1px solid #542f9e;
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
}

// form-group
.form-group {

  input,
  select {
    &:not([type='checkbox']) {
      height: 36px;
      // border-radius: 2px;
      font-size: 14px;

      &:not(.is-invalid) {
        border: 1px solid #ccc;
      }

      &:focus {
        border: 1px solid #542f9e;
        // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      }
    }
  }

  .col-form-label {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #542f9e;
  background-color: #542f9e;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: #542f9e;
  background-color: #542f9e !important;
  opacity: 0.7;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #fff !important;
}

// form-table
.form-table {
  width: 100%;

  th,
  td {
    word-break: break-word;
    display: revert;
    white-space: revert;
    text-align: left;
    padding: 0.75rem 1.25rem;
  }

  .table-label {
    background-color: #eeeaf5;
    color: #542f9e;
    width: 25%;
    text-align: right;
  }
}

.input-group-text {
  background-color: #542f9e;
  border-color: #542f9e !important;
  border: 1px solid #542f9e;
  color: #fff;
}

// typeahead
.typeahead-list-group {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-height: 400px;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.typeahead-list-group-item {
  position: relative;
  display: block;
  padding: .55rem 1.25rem;
  background-color: #fff;
  cursor: pointer;
  font-weight: normal;
}

.typeahead-list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #542f9e;
  border-color: #542f9e;
}

// custom panel

.app-panel {

  .panel-header {
    padding: 5px 20px;
    background-color: #542f9e;
    border: 1px solid #542f9e;
    color: #fff;
  }

  .panel-body {
    padding: 10px 20px;
    font-weight: normal;
    border: 1px solid #e2e5e8;
  }
}

// table
th,
td {
  font-family: $theme-font-family !important;
}

// modal
@media (min-width: 992px) {

  .modal-super-lg {
    max-width: 1400px;
  }
}

.modal-header {
  padding: 15px 30px;
  background-color: #f5f5f5;

  .close {
    padding: 15px 30px;
    top: 20px;
    right: 20px;
  }
}

.modal-footer {
  padding: 20px 30px;
  border: none;

  .app-modal-footer {
    margin: 0;
  }
}

.modal-body {
  padding: 20px 30px 0 30px;
}

// badge
.badge {
  color: #333;
}

.pull-right {
  float: right !important;
}

.dual-list {
  .button-bar {
    .btn-primary {
      background-color: #542f9e;
      border-color: #542f9e;
    }

    .btn-default {
      border-color: #e7e0f5;
      color: $purple;
      background-color: #e7e0f5;

      // &:hover {
      //   color: #fff;
      //   background: $indigo;
      //   border-color: $indigo;
      // }
    }
  }

  .listbox {

    .point-left,
    .point-right {
      background-color: #542f9e;
      border-color: #542f9e;
    }
  }

  .record-picker {
    border-radius: 0 !important;

    li.selected {
      background-color: #e7e0f5 !important;
    }

    li {
      border-radius: 0 !important;
    }
  }

  .record-picker::-webkit-scrollbar {
    width: 4px !important;
  }

  .record-picker::-webkit-scrollbar-thumb {
    background: #eee !important;
    border-radius: 3px !important;
    border: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .record-picker::-webkit-scrollbar-track {
    background: #fff !important;
    box-shadow: none !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.uw-accordion {
  .card {
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 0;

    .card-header {
      border-bottom: none;
      padding: 0.5rem 1.25rem;
      background-color: #542f9e;

      .btn-link {
        padding: 0;
        width: 100%;
        text-align: left;

        a {
          font-size: 14px;
          color: #fff;
        }
      }

    }

    .card-body {
      .group-header {
        font-weight: normal;
        background-color: #EAE6F2;
        color: #542f9e;
        border-color: #EAE6F2;
      }
    }
  }

  &.light {
    .card .card-header {
      background-color: #EAE6F2;

      .btn-link {
        a {
          color: #542f9e;
        }
      }
    }
  }

  &.card-body-p0 {
    .card .card-body {
      padding: 0;
    }
  }
}

.custom-mini-table {

  td,
  th {
    padding: 10px 1.2rem;
    white-space: normal;
    background: #fff;
  }
}

.contact-list-popover,
.font-list-popover {
  max-width: 100%;

  .popover-body {
    padding: 0;

    .popover-table {
      margin-bottom: 0;

      td,
      th {
        padding: 0.75rem;
        text-transform: none;
        color: #333;
        border-top: none;
        background: inherit;
        font-size: 12px;
      }
    }
  }
}

.font-list-popover {
  border-radius: 0;

  .popover-body {
    height: 700px;
    overflow: auto;
  }
}

.custom-popover {
  max-width: 100%;
  border: none;

  &.bs-popover-top {
    .arrow {
      bottom: -0.5rem;
    }

    .arrow::before,
    .arrow::after {
      border-top-color: #394c62;
    }
  }

  &.bs-popover-left {
    .arrow {
      right: -0.5rem;
    }

    .arrow::before,
    .arrow::after {
      border-left-color: #394c62;
    }
  }

  &.bs-popover-right {
    .arrow {
      left: -0.5rem;
    }

    .arrow::before,
    .arrow::after {
      border-right-color: #394c62;
    }
  }

  &.bs-popover-bottom {
    .arrow {
      top: -0.5rem;
    }

    .arrow::before,
    .arrow::after {
      border-bottom-color: #394c62;
    }
  }

  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #fff;
    border-radius: 5px;
    border: none;
    background: #394c62;

    .popover-table {
      margin-bottom: 0;
    }
  }

  &.small-popover {
    font-size: 13px !important;
    max-width: 276px !important;

    .popover-body {
      padding: 0.3rem 0.7rem !important;
    }
  }

  &.middle-popover {
    font-size: 13px !important;
    max-width: 476px !important;

    .popover-body {
      padding: 0.3rem 0.7rem !important;
    }
  }
}

.swal2-styled.swal2-confirm {
  min-width: 120px;
  background: $purple !important;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.swal2-styled.swal2-cancel {
  min-width: 120px;
}

.divider {
  display: block;
  overflow: hidden;
  margin: 1rem 0;
  text-align: center;
  white-space: nowrap;

  .divider-text {
    position: relative;
    display: inline-block;
    padding: 0 1rem;
    background-color: #fff;

    &::before {
      right: 100%;
      position: absolute;
      top: 50%;
      width: 9999px;
      content: '';
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    &::after {
      left: 100%;
      position: absolute;
      top: 50%;
      width: 9999px;
      content: '';
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.text-overflow {
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.backdrop {
  border-radius: 0 !important;
}

.custom-file {
  height: 36px;
  cursor: pointer;

  .custom-file-label {
    margin-bottom: 0;
    height: 36px;
    padding: 0.4rem 0.6rem;
    color: #999;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &::after {
      height: 34px;
      padding: 0.4rem 0.6rem;
      background-color: #e7e0f5;
    }
  }
}

.text-ellipsis {
  text-transform: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
  display: inline-block;
}

.text-break-word {
  word-break: break-word;
  white-space: normal;
}

.icon-large {
  i {
    font-size: 16px;
  }
}

.table-fixed {
  table-layout: fixed;
}

.clear-date {
  &::after {
    position: absolute;
    content: "×";
    color: #999;
    left: -12px;
    top: 6px;
    font-size: 16px;
    font-style: normal;
  }
}

.wider-select {
  width: auto !important;
  min-width: 100%;
  max-width: 400px;

  &.ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .ng-option {
        .ng-option-label {
          padding-right: 10px;
        }
      }
    }
  }
}

.ng-select.ng-select-multiple {
  .ng-select-container {
    padding: 5px 1.1875rem 0 1.1875rem;
    .ng-value-container {
      .ng-value {
        margin-top: 0;
      }
    }
  }
}

.ngx-datatable {
  &.no-data {
    .datatable-header {
      overflow-x: scroll;
    }
  }
}

.no-padding {
  padding: 0 !important;
}

.typeahead-list-group {
  max-height: 300px !important;
}
