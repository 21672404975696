.with-placeholder {
  .ng-placeholder {
    display: block !important;
  }
}

.ng-select .ng-select-container {
  // min-height: 30px;
  border: 1px solid #ddd;
  // height: 36px;
  padding: 0 1.1875rem;
}

.ng-select.ng-select-single {
  .ng-select-container {
    // height: 36px;
    border: 1px solid #ccc;
    padding: 0 1.1875rem;
    border-radius: 0;

    .ng-value-container {
      padding-left: 0;

      .ng-input {
        padding: 0 1.1875rem;
        top: inherit;
      }

      input {
        height: auto;
      }

      .ng-placeholder {
        top: 6px;
        padding-bottom: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }

    .ng-value {
      width: 100%;
    }
  }

  &.mini {
    .ng-select-container {
      // height: 30px;
      padding: 0 0 0 0.5rem;

      .ng-value-container {
        .ng-input {
          padding: 0 0.5rem;
        }

        input {
          height: auto;
        }

        .ng-placeholder {
          top: 6px;
          padding-bottom: 0;
          padding-bottom: 0;
          padding-left: 0;
        }
      }

      .ng-value {
        width: 100%;
      }
    }
  }

  &.is-invalid {
    .ng-select-container {
      border: solid 1px #ff5252;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border: 1px solid #542f9e;
    }
  }
}

.ng-select.ng-select-multiple {
  .ng-select-container {
    // height: 36px !important;
    border: 1px solid #ccc;
    padding: 0 1.1875rem;
    border-radius: 0;

    .ng-value-container {
      padding-left: 0;
      padding-top: 0;

      input {
        height: auto;
      }

      .ng-input {
        padding: 0;
      }

      .ng-placeholder {
        top: 6px;
        padding-bottom: 0;
        padding-bottom: 0;
        padding-left: 0;
      }
    }

    .ng-value {
      margin-bottom: 7px;
      margin-top: 7px;
      border-radius: 0 !important;
      background-color: #542f9e !important;
      color: #fff;

      .ng-value-icon {
        border-right: 1px solid #fff !important;

        &:hover {
          background-color: #6f42c1 !important;
        }
      }
    }
  }

  &.is-invalid {
    .ng-select-container {
      border: solid 1px #ff5252;
    }
  }

  &.ng-select-opened {
    .ng-select-container {
      border: 1px solid #542f9e;
    }
  }
}

.ng-select.ng-select-opened>.ng-select-container {
  border-color: #ddd;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #ddd;
  box-shadow: none;
}

.ng-dropdown-panel {
  border: 1px solid #542f9e;

  &.ng-select-bottom {
    border-radius: 0;
  }

  &.renewal-status-dropdown {
    .ng-dropdown-panel-items {
      .ng-option {
        padding: 0 !important;
      }
    }
  }

  .ng-dropdown-panel-items {
    .ng-option {
      padding: 0.425rem 1.1875rem;

      &.ng-option-marked {
        background-color: #EAE6F2;
      }

      &.ng-option-selected {
        background-color: #ddd;

        &.ng-option-marked {
          background-color: #EAE6F2;
        }
      }
    }
  }

  &.mini {
    .ng-dropdown-panel-items {
      .ng-option {
        padding: 4px 9px;
      }
    }
  }
}
